.table-text0-small {
  font-size: 12px;
}

.text-link a {
  color: rgb(177, 177, 177);
}

.text-link a:hover {
  color: #fca120;
}
