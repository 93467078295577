#btn-nav-user {
  background-color: #865ddd !important;
  color: #fff;
  border-radius: 5px;
}

.text-of-nav-cand {
  font-size: 12px;
}

.main-li-of-nav {
  font-size: 12px;
  color: #707070 !important;
}
.linkhighlight {
  color: blue !important;
}

.bg-imgage {
  background-image: url("../../Assests/modalBG.png");
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
}
