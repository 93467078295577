:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
  --blue: #362658;
}

.applicants-video-head-recr {
  color: var(--purple);
  font-weight: bold;
}

.edit-text-rexr {
  text-align: right;
  color: var(--purple);
  font-size: 20px;
}

.edit-btn-apll-vid-rexr{
    background-color: #fff;
    border: 0;
    color: var(--purple);
    font-weight: bold;
    font-size: 20px;
}

.applic-stat-recr {
  color: var(--purple);
  font-size: 20px;
  font-weight: bold;
}

.app-stst-para {
  color: var(--grey);
  font-size: 12px;
}

.shortlist-text-rexr {
  color: var(--purple);
  font-weight: bold;
}

.prog-up-recr {
  color: var(--grey);
  font-weight: bold;
}

.name-applicants-recr {
  font-size: 25px;
  color: var(--blue);
  font-weight: bold;
}

.date-of-appli-rexr {
  text-align: right;
  font-size: 12px;
  color: var(--grey);
}

.prof-appli-rexr {
  font-weight: bold;
  color: var(--grey);
}

.loc-recr-vid {
  color: var(--grey);
  font-size: 14px;
  /* font-weight: bold; */
}

.view-prof-btn-rexr {
  color: #fff !important;
  background-color: #865ddd !important;
  font-weight: bold !important;
  border: 0;
}

.view-prof-btn-rexr:hover {
  background-color: #865ddd !important;
}

.view-prof-btn-rexr:focus {
  background-color: #865ddd !important;
}

.view-prof-btn-rexr:active {
  background-color: #865ddd !important;
}
@media only screen and (max-width:712px){
  .ReactModal__Content{
    width: 340px !important;
  }
}
